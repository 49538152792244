<template>
  <v-app>
    <AppHeader/>
    <v-content>
    <!-- <Home/> -->
    <router-view/>
    </v-content>
    <AppFooter/>
  </v-app>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
// import Home from './components/Home.vue'
import AppFooter from './components/AppFooter.vue'

export default {
  components: {
    AppHeader,
    // Home,
    AppFooter
  }
}
</script>
<style lang="scss" scoped>
.theme--dark.v-application {
  background: #101E33;
}

label {
  color: red;
}

.v-main {
  font-family: Averta, "Avenir W02", Avenir, Helvetica, Arial, sans-serif;
}
</style>
