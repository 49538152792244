<template>
  <section class="home">
    <HomeHero/>
    <!-- <About/> -->
  </section>
</template>

<script>
import HomeHero from './HomeHero'
// import About from './About'

export default {
  components: {
    HomeHero
    // About
  }
}

</script>

<style lang="scss" scoped>
.home {
  padding-bottom: 50px;
}

</style>
