import ja from '../lang/ja.json'
import en from '../lang/en.json'
import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n)
export default new VueI18n({
  locale: localStorage.getItem('lang') || 'navigator.language',
  messages: {
    ja: ja,
    en: en
  }
})
