<template>
    <header>
      <v-app-bar
        app
        style="width: 100%;"
      >
      <v-app-bar-nav-icon
      style="position: absolute;right: 10px;"
      class="d-lg-none d-xl-none"
      @click="drawer = true"
      right
      ></v-app-bar-nav-icon>
        <v-toolbar-title>
        <v-img
        v-model="theme"
        :src='themeImg'
        max-height="60"
        max-width="200"
        ></v-img>
        </v-toolbar-title>
        <v-tabs cols="6" class="d-none d-lg-block">
          <v-tab
          v-for="(menuItem, index) in menuItems"
          :key="index"
          :to="menuItem.url"
          >
          {{ menuItem.name }}
          </v-tab>
          <!-- ここから -->
        <!-- ここまで -->
        </v-tabs>
        <v-col class="btns">
          <v-col class="d-none d-lg-block">
          <div>
          <v-switch
          class="weather-btn"
          v-model="theme"
          :prepend-icon="themeIcon">
          </v-switch>
        </div>
          </v-col>
        <v-col class="d-none d-lg-block" style="width:200px">
        <v-select
          v-model="lang"
          :items="items"
          @change="handleChange(items, $event)"
          item-text="label"
          item-value="id"
          solo
          return-object
          style="height: 20px;"
        />
        </v-col>
        <v-col class="d-none d-lg-block LaunchApp-btn">
          <v-btn style="width:150px; top:23px;">
            Launch App
          </v-btn>
        </v-col>
        </v-col>
      </v-app-bar>
        <v-navigation-drawer
        v-model="drawer"
        temporary
        fixed
        right
        >
        <v-list
          nav
          dense
        >
          <v-list-item-group>
            <v-list-item
              v-for="(menuItem, index) in menuItems"
              :key="index"
              :to="menuItem.url"
            >
              <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
          </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-col class="btns2">
          <v-col class="beside-nav">
          <div>
          <v-switch
          v-model="theme"
          :prepend-icon="themeIcon"></v-switch>
        </div>
          </v-col>
        <v-col>
        <v-select
          v-model="lang"
          :items="items"
          @change="handleChange(items, $event)"
          item-text="label"
          item-value="id"
          solo
          return-object
        />
        <v-select
          v-model="lang"
          :items="items"
          @change="handleChange(items, $event)"
          item-text="label"
          item-value="id"
          solo
          return-object
        />
        </v-col>
        <v-col>
        <v-btn style="width:150px; top: 30px;">Launch App</v-btn>
        </v-col>
        </v-col>
      </v-navigation-drawer>
    </header>
</template>

<script>
import constants from '../common/constants'

export default {
  data () {
    const lang = localStorage.getItem('lang') || 'ja'
    return {
      drawer: false,
      items: [
        { label: '日本語', id: 'ja' },
        { label: 'English', id: 'en' }
      ],
      menuItems: constants.menuItems,
      theme: false,
      lang: lang,
      lightImg: require('../assets/espaycrow-logo-dark.png'),
      darkImg: require('../assets/espaycrow-logo-white.png')
    }
  },
  watch: {
    theme () {
      this.$vuetify.theme.dark = this.theme
    }
  },
  computed: {
    themeIcon () {
      return this.theme ? 'mdi-weather-night' : 'mdi-weather-sunny'
    },
    themeImg () {
      return this.theme ? this.darkImg : this.lightImg
    }
  },
  methods: {
    handleChange (items, event) {
      localStorage.setItem('lang', event.id)
      window.location.reload()
    }
  }
}
</script>

<style lang="scss">
.v-toolbar__title {
  overflow: visible !important;
}

@media screen and (min-width:768px) {
  .v-toolbar__content {
    margin: 0 16vw;
    padding: 0 32px;
  }
}
@media screen and (min-width:960px) {
  .v-toolbar__content {
    margin: 0 5vw;
    padding: 0 32px;
  }
}
@media screen and (min-width:1024px) {
  .v-toolbar__content {
    margin: 0 8vw !important;
    padding: 0 32px;
  }
}
@media screen and (min-width:1150px) {
  .v-toolbar__content {
    margin: 0 10vw !important;
  }
}
@media screen and (min-width:1200px) {
  .v-toolbar__content {
    margin: 0 13vw !important;
  }
}
@media screen and (min-width:1263px) {
  .v-toolbar__content {
    margin: 0 15vw !important;
  }
}
@media screen and (min-width:1280px) {
  .v-toolbar__content {
    margin: 0 3vw !important;
    padding: 0 32px;
  }
}
@media screen and (min-width:1680px) {
  .v-toolbar__content {
    margin: 0 16vw !important;
    padding: 0 32px;
  }
}
@media screen and (min-width:1860px) {
  .v-toolbar__content {
    margin: 0 18vw !important;
  }
}
@media screen and (min-width:1904px) {
  .v-toolbar__content {
    margin: 0 22vw !important;
    padding: 0 32px;
  }
}
// @media screen and (min-width:1264px) {
//   .v-toolbar__title {
//     margin-left: 15vw;
//   }
//   .btns {
//     margin-right: 15vw;
//   }
// }

.field-width {
  width: 150px !important;
}

.v-tabs {
  width: 50vw !important;
}

.button.r {
  margin-right: 10px;
  text-align: right;
}

.btns{
  display: flex;
}

.beside-nav {
  margin: 20px;
}

.weather-btn {
  margin-top: 50px !important;
  width: 50px;
}

.LaunchApp-btn {
  margin-top: 20px;
}

.d-none .d-lg-block .d-xl-none {
  margin-left: 350px;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  height: 40px;
  top: 37px;
}

</style>
