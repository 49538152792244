export default {
  menuItems: [
    {
      name: 'HOME',
      url: '/'
    },
    {
      name: 'ABOUT',
      url: '/about'
    }
  ]
}
