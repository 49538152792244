<template>
  <section class="home-hero">
    <v-container class="home-hero__content">
      <v-row no-gutters justify="center">
        <v-col cols="12" align="center" class="home-hero__content-text" sm="8" md="6" lg="6" xl="6">
          <v-col outlined tile class="v-col__content flex-column">
          <h1 class="theme-style">{{ $t("message.Theme") }}</h1>
          <p class="font-size">
            {{ $t("message.Explanation") }}
          </p>
          <p>{{ $t("message.Caution1") }}</p>
          </v-col>
          <v-col outlined tile class="v-col__content">
            <v-col width="500px" class="double-line">
              <h1 class="text-center">{{ $t("message.Money-Save") }}</h1>
              <v-divider></v-divider>
              <p class="text-center text-primary">{{ $t("message.Domestic") }}</p>
              <p class="text-center text-primary">『国内の節約できる金額』</p>
              <p class="text-center text-primary">{{ $t("message.Overseas") }}</p>
              <p class="text-center text-primary">『海外の節約できる金額』</p>
            </v-col>
          </v-col>
          <v-col outlined tile class="v-col__content">
          <p>{{ $t("message.Caution2") }}</p>
          </v-col>
          </v-col>
        <v-spacer lg="1" xl="1"></v-spacer>
        <v-col cols="12" align="center" style="padding: 0 10px" class="home-hero__content-text" sm="8" md="5" lg="5" xl="5">
          <v-col outlined tile height="70vh" class="v-col__content v-col__content_bottom">
            <v-col style="text-align: right;" class="form-width">
            <v-btn> {{ $t("message.Calculate") }} </v-btn>
            </v-col>
          <v-col
          cols="12"
          style="display:flex; padding: 0; border: 1px solid; height: 72px; border-radius: 3px;"
          class="v-col__content_top v-col__content_bottom"
        >
          <v-text-field
            v-bind:label="$t('message.Goods-Services')"
            outline
            class="colortext-field"
            style="height: 72px; font-size: 20px; padding:0; margin: 0;"
            hide-details="false"
            type="text"
          ></v-text-field>
          <v-select
          :items="items"
          label="JAY"
          filled
          single-line
          style="height: 72px;"
          class="colortext-field field-width"
          hide-details="false"
        ></v-select>
        </v-col>
        </v-col>
        <v-col class="calculation-all">
        <v-col cols="1" class="calculation-root">
        </v-col>
        <v-row no-gutters>
        <v-col cols="12" class="tw-calculator-1">
          <v-col class="root-aqua tt">
            <v-col class="radius2-1"><span class="Mathematical-notation2"></span><span class="sentive2">***,321,***,321,***.1234 EAR {{ $t("message.Conversion-amount-EAR") }}</span></v-col>
            <v-col class="radius2"><span class="Mathematical-notation2 Mathematical-notation2-1">−</span><span class="sentive4">***,***,***,***,***.**** EAR &ensp; {{ $t("message.Creative-reward") }}</span></v-col>
            <v-col class="radius2"><span class="Mathematical-notation2">+</span>
            <span class="Incentive-left sentive1">***,***,***,***,***.**** EAR &ensp; {{ $t("message.Incentive") }}</span></v-col>
            <v-col class="radius2 spacer-top"><span class="Mathematical-notation2 equal-position">=</span>
            <span class="sentive1 receipt-blue">
              <span class="buyer-recipt buyer-recipt2">
              ***,***,***,***,***.**** EAR
              </span>
              &ensp;
              <span class="seller-buyer">{{ $t("message.Seller-Receipt") }}</span>
              </span>
              </v-col>
              <v-col class="USD-aqua" style="color: #00bfff; padding-top: 0;">***,***,***,***,** USD</v-col>
          </v-col>
        </v-col>
        <v-col class="text-left radius-all">
          <v-col class="radius1 test2" style="padding:0 height: 5vw;"><span class="Mathematical-notation1">×</span>
          <v-col class="percent-space" style="display: flex; padding-top: 0;">
            <v-col style="padding: 0; flex:1;">
              <v-text-field
              label=""
              outlined
              single-line
              dense
              suffix="%"
              hide-details="false"
              style="width:100px; padding:0;padding-top:0;"
              height="10"
              class="colortext-field testm">{{ $t("message.Calculation-ratio") }}
              </v-text-field>
            </v-col>
          </v-col>
          <v-col style="flex:3; padding-right: 0; padding-top:0;" class="Escrow-meney">{{ $t("message.Calculation-ratio") }}</v-col>
          </v-col>
        </v-col>
        </v-row>
          </v-col>
        <v-col
          cols="12"
          style="display:flex; padding: 0;
    border: 1px solid; height: 72px; border-radius: 3px;"
          class="v-col__content_top v-col__content_bottom"
        >
          <v-text-field
            v-bind:label="$t('message.Remittance')"
            outline
            class="colortext-field"
            style="height: 72px; font-size: 20px; padding:0; margin: 0;"
            hide-details="false"
            type="text"
          ></v-text-field>
          <v-select
          :items="items"
          label="JAY"
          filled
          single-line
          class="colortext-field field-width"
          style="width: 10vw; height: 72px;"
          hide-details="false"
        ></v-select>
        </v-col>
        <v-col cols="12" class="tw-calculator-2">
          <v-col class="root-aqua tt root-area">
            <v-col class="radius2-1"><span class="Mathematical-notation2"></span><span class="sentive2">***,***,***,***,***.**** EAR &ensp; {{ $t("message.Conversion-amount-EAR") }}</span></v-col>
            <v-col class="radius2"><span class="Mathematical-notation2 Mathematical-notation2-1">−</span>
            <span class="sentive3">***,321,***,321,***.1234 EAR {{ $t("message.Goods-Services") }}</span></v-col>
            <v-col class="radius2"><span class="Mathematical-notation2">−</span><span class="sentive4">***,***,***,***,***.**** EAR &ensp; {{ $t("message.Creative-reward") }}</span></v-col>
            <v-col class="radius2"><span class="Mathematical-notation2">+</span>
            <span class="sentive3">***,***,***,***,***.**** EAR &ensp; {{ $t("message.Incentive") }}</span></v-col>
            <v-col class="radius2 spacer-top radius-position"><span class="Mathematical-notation2 equal-position">=</span>
            <span class="sentive1 receipt-blue">
              <span class="buyer-recipt buyer-recipt2">
              ***,***,***,***,***.**** EAR
              </span>
              &ensp;
              <span class="seller-buyer"> {{ $t("message.Buyer-Refund-Receipt") }}</span></span>
            </v-col>
          <v-col style="color: #00bfff; padding-top: 0;">***,***,***,***,** USD</v-col>
          </v-col>
        </v-col>
        <v-col class="btn-vertical">
        <v-btn style="color:#00e5e5; background-color:white;" class="btn-width-btm btn-top"> {{ $t("message.Purchase") }} </v-btn>
        <v-btn style="color:white; background-color: #00e5e5;" class="btn-width-btm"> {{ $t("message.Create") }} </v-btn>
        </v-col>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data () {
    return {
      items: ['JAY', 'USD', 'EUR'],
      momo: ''
    }
  }
}

</script>
<style lang="scss">
.home-hero {
  // padding-right: 200px;
  // padding-left: 200px;
  background-size: cover;
  width: 100%;
  height: 100%;
  // background-color: #004e93;
}
.home-hero__content {
  // background-color: red;
    // height: 800px;

  &-text {
    // text-align: center;
    font-size: 50px;
    font-weight: bold;
  }
}

h1, p {
  text-align: left;
  // color: white;
}

.v-col__content_bottom {
  padding: 0;
}

.v-col__content_top {
  padding-top: 0;
}

h1 {
  font-weight: bold;
  font-size: 27px;
  padding: 20px;
}

.font-size {
  font-size: 20px;
}

.line-left {
  border-left: thick solid #a1c6b5;
  padding-left: 10px;
  width: 300px;
}

.text-center {
  text-align: center;
  color: black;
  padding-top: 10px;
}
  .colortext-field {
    width: 290px;
  }

.field-width {
  width: 5vw;
}

// .v-text-field__details {
//  position: absolute;
// }

.form-width {
  padding-left: 0 !important;
  bottom: 0;
  // position: absolute;
}

.double-line {
  border: 6px double #000000;
  padding: 15px;
  background: white;
}

.text-primary {
  color: #007bff;
  font-weight: bold;
}

.calculation-root {
  border-right: 2px solid #003166;
  width: 25px;
  height: 300px;
  padding: 0;
  position: relative;
  text-align: left;
}

.text-left {
  text-align: left;
  font-weight: bold;
  padding-left: 0;
}

.calculation-all {
  display: flex;
  padding: 0;
  height: 300px;
}

// .seller-buyer {
//   position: absolute;
//   bottom: 0;
//   font-weight: bold;
// }

.buyer-style {
  bottom: 0;
  font-weight: bold;
  text-align: left;
}

.left-side {
  padding: 0;
}

.radius-all {
  position: relative;
}

.radius1 {
  padding: 30px 22px 0 22px;
  display: flex;
  margin-top: 10px;
}

.radius2 {
  display: flex;
  padding: 4px 0 4px 12px;
}

.radius2.spacer-top {
  padding-top: 10px !important;
  padding-left: 10px;
  padding-right: 20px;
}

.radius2-1 {
  padding: 20px 0 4px 0;
  display: flex;
  max-width: 120%;
}

.radius1::before {
  content: "";
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #003166;
  position: absolute;
  margin: 5px 0 0 -35px;
}

.radius2::before {
  content: "";
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #00bfff;
  position: absolute;
  margin-left: -39px;
}

.radius2-1::before {
  content: "";
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: #00bfff;
  position: absolute;
  margin-left: -17px;
  margin-top: 6px;
}

.btn-vertical {
  height: 100px;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

.btn-width-btm {
  width: 300px;
  margin-bottom: 20px;
}

.btn-top {
  margin-top: 36px;
}

.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
  padding-left: 0;
}

.Mathematical-notation1 {
  color: white;
  margin-left: -28px;
  position: absolute;
  margin-top: 5px
}

.Mathematical-notation2 {
  color: white;
  margin-left: -31px;
  position: absolute;
}

.Mathematical-notation2-1 {
  margin-top: 3px;
}

.raito {
  text-align: center;
}

.raito-frame {
  border: 1px solid;
  border-radius: 10px;
  background: white;
  width: 100px;
}

.raito-position {
  margin: 0;
}

.Escrow-meney {
  padding-bottom: 0 !important;
  padding-top: 5px !important;
  width: 11vw;
}
// .v-text-field--filled {
//   border-radius: 4px 0 0 0;
// }

label.v-label.theme--light {
  top: 12px;
  font-size: .875rem;
  left: 14px !important;
}

label.v-label.theme--dark {
  top: 12px;
  left: 14px !important;
  font-size: 0.875rem;
}

// .v-text-field .v-label--active {
//     max-width: initial;
//     transform: initial;
//     pointer-events: initial;
// }

.v-select__slot>label.v-label.theme--light {
  top: 25px !important;
  font-size: 18px !important;
}

.v-select__slot>label.v-label.theme--dark {
  top: 25px !important;
  font-size: 18px !important;
}

.v-icon.notranslate.mdi.mdi-menu-down.theme--light {
  top: 2px;
}

.v-icon.notranslate.mdi.mdi-menu-down.theme--dark {
  top: 2px;
}

.root-aqua {
  border-left: 2px #00bfff solid;
  margin-left: 30px;
  padding-top: 0;
  height: 180px;
}

.root-aqua.tt.root-area {
  width: 30vw;
  height: 220px;
}

.sentive1 {
  text-align: left;
  padding: 4px 0;
  font-size: 15px;
}
.sentive1-1 {
  text-align: left;
  padding: 4px 0;
  font-size: 13px;
}

.radius2.col:nth-child(2) {
  font-size: 13px;
}

.sentive2 {
  text-align: left;
  padding: 4px 0 4px 12px;
  font-size: 15px;
  margin-right: -90px;
}
.sentive3 {
  text-align: left;
  padding: 4px 0;
  font-size: 15px;
  margin-right: -30px;
}
.sentive4 {
  text-align: left;
  padding: 4px 0;
  font-size: 15px;
  width: 270px;
}

.buyer-recipt {
  background-color: #00bfff;
  color: white;
  font-size: 23px;
  padding: 10px 5px;
  border-radius: 7px;
  margin-top: 20px;
}

// .col {
//   max-width: 130%;
// }

.tw-calculator-1 {
  width: 25vw;
  height: 200px;
}

.tw-calculator-2 {
  width: 520px;
  height: 300px;
  padding: 0;
}

.Mathematical-notation2.equal-position {
  margin-left: -29px;
  margin-top: 5px;
}

.radius2.spacer-top::before {
  margin-left: -36px;
  margin-top: 5px;
}
.receipt-blue {
  margin-right: -100px;
}

@media (min-width: 1904px) {
  .container {
    max-width: initial;
  }
}

@media screen and (max-width:1680px) {
  .calculation-all {
    height: 340px;
  }
  .calculation-root {
    height: 340px;
  }
  .root-aqua {
    height: 210px !important;
  }
  .tw-calculator-1 {
    height: 230px !important;
  }
  .sentive2 {
    width: 430px !important;
  }
  .root-area {
    height: 250px !important;
  }
}
@media screen and (max-width: 1820px) {
  .tw-calculator-1 {
    height: 220px !important;
  }
  .calculation-all {
    height: 320px !important;
  }
  .calculation-root {
    height: 320px !important;
  }
  .root-aqua {
    height: 190px !important;
  }
  .root-area {
    height: 230px !important;
  }
}

@media screen and (min-width:1264px) {
  .container {
    max-width: 1164px !important;
  }
}

@media screen and (max-width:1440px) {
  .receipt-blue {
    margin-right: -100px !important;
    width: 300px !important;
  }
  .tw-calculator-1 {
    height: 240px !important;
  }
  .calculation-all {
    height: 340px !important;
  }
  .buyer-recipt {
    padding: 3px 5px !important;
  }
  .calculation-root {
    height: 340px !important;
  }
}
@media screen and (max-width:1280px) {
  h1 {
    font-size: 36px !important;
  }
  .tt {
    height: 260px !important;
  }
  .sentive1-1 {
    width: 270px;
    font-size: 15px;
    padding-left: 12px;
  }
  .root-area {
    // height: 280px !important;
    height: 320px !important;
  }
  .tw-calculator-1 {
    height: 300px !important;
    width: 100%;
  }
  .tw-calculator-2 {
    width: 500px;
    height: 400px !important;
    margin-left: -20px;
  }
  .receipt-blue {
    width: 270px !important;
    margin-right: 0;
  }
  .radius2::before {
    position: initial;
  }
  .calculation-root {
    height: 400px !important;
  }
  .buyer-recipt {
    padding: 2px;
    font-size: 20px;
    margin-right: 20px;
  }
  .spacer-top {
    padding: 0;
  }
  .Incentive-left {
    text-align: left;
  }
  .Escrow-meney {
    width: 180px;
    padding-left: 0;
  }
  .percent-space {
    padding: 0 10px!important;
  }
  .sentive3 {
    // width: 230px;
    width: 250px;
    text-align: left;
    font-size: 17px;
    padding-left: 10px;
  }
  .calculation-root {
    height: 420px;
  }
  .sentive2 {
    width: 260px !important;
    font-size: 17px;
  }
  .sentive1 {
    font-size: 17px;
    width: 300px;
    padding-left: 10px;
  }
  .calculation-all {
    height: 400px!important;
  }
  .root-aqua {
    width: initial !important;
  }
  .sentive4 {
    padding-left: 10px;
    font-size: 17px;
    width: 290px !important;
  }
  div.radius2.spacer-top.radius-position.col::before {
  // .radius-position {
    // margin-left: -72px;
    margin-left: -35px;
  }
  .radius2.spacer-top.radius-position.col>.Mathematical-notation2.equal-position {
  // margin-left: -66px !important;
  margin-left: -28px;
  }
}

h1 {
  font-family: Averta, "Avenir W02", Avenir, Helvetica, Arial, sans-serif;
  font-size: 2.625rem;
}

.theme--light.v-input,.theme--dark.v-input {
  padding: 0;
  margin: 0;
  height: 72px;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: initial;
}

.v-text-field>.v-input__control>.v-input__slot>.v-text-field__slot {
  height: 70px;
}
label.v-label.v-label--active.theme--light.primary--text {
  font-size: 18px;
  top: 30px;
}
label.v-label.v-label--active.theme--dark.primary--text {
  font-size: 18px;
  top: 30px;
}
.v-input input {
  max-height: initial;
}

div.v-text-field__slot>input {
  margin: 10px;
  padding-top: 20px;
}

.home-hero__content-text {
  font-size: 15px;
}

.v-select>.v-input__control>.v-input__slot {
  height: 70px;
}

.v-text-field--outlined .v-text-field__slot, .v-text-field--single-line .v-text-field__slot {
  height: 40px !important;
}

.v-text-field.v-input--dense>div.v-input__control>div.v-input__slot>div.v-text-field__slot>input {
  padding: 0;
}

@media screen and (max-width: 960px) {
  .spacer {
    flex-grow: initial !important;
  }
}

@media screen and (max-width: 768px) {
  .spacer {
    flex-grow: initial !important;
  }.root-area {
    margin-left: 100px;
  }
}

@media screen and (max-width:540px) {
  .sentive4 {
    width: 315px;
  }
  .tt {
    height: 240px !important;
  }
  .root-area {
    height: 310px !important;
    margin-left: 100px;
  }
}

@media screen and (max-width:414px) {
  h1 {
    padding: 20px 0;
    font-size: 35px !important;
  }
  div.radius2.spacer-top.radius-position.col::before {
    margin-left: -35px;
  }
  .radius2.spacer-top.radius-position.col > .Mathematical-notation2.equal-position {
    margin-left: -30px !important;
  }
  .radius2.spacer-top::before {
    margin-left: -36px;
  }
  .radius2.spacer-top {
    padding-right: 0;
  }
  .root-aqua {
    height: 260px !important;
  }
  .root-area {
    margin-left: 50px;
    height: 320px !important;
  }
  .sentive4 {
    width: 280px;
  }
  .radius2.spacer-top.radius-position.col > .Mathematical-notation2.equal-position {
    margin-left: -28px !important;
  }
}
@media screen and (max-width:380px) {
  .radius1 {
    width: 350px;
  }
  .radius2.spacer-top {
    padding-top: 0 !important;
  }
  .radius2.spacer-top::before {
    margin-left: -36px;
  }
  h1 {
    padding: 20px 0;
    font-size: 30px !important;
  }
  .home-hero__content-text.col-sm-8.col-md-6.col-lg-5.col-xl-4.col-12 {
    padding: 0 !important;
  }
  .sentive1 {
    width: 230px;
  }
  .sentive4 {
    font-size: 17px;
    width: 260px;
  }
  .Mathematical-notation2.equal-position {
    margin-left: -28px !important;
  }
  div.radius2.spacer-top.radius-position.col::before {
    margin-left: -35px;
  }
  .radius2.spacer-top.radius-position.col > .Mathematical-notation2.equal-position {
    margin-left: -28px !important;
  }
  .tw-calculator-1 {
    width: 200px !important;
  }
  .root-area {
    margin-left: 30px;
  }
  .buyer-recipt {
    margin-right: 0;
  }
  .USD-aqua {
    width: 200px;
  }
  .calculation-root {
    max-width: 15px;
  }
  .container {
    padding: 0;
  }
}

@media screen and (max-width:360px) {
  h1 {
    font-size: 32px !important;
  }
  .radius2.spacer-top::before {
    margin-left: -50px;
  }
  div.radius2.spacer-top.radius-position.col::before {
    margin-left: -65px;
  }
  .Mathematical-notation2.equal-position {
    margin-left: -44px !important;
  }
  div.radius2.spacer-top.radius-position.col {
        margin-left: 35px;
  }
  .radius2.spacer-top.radius-position.col > .Mathematical-notation2.equal-position {
    margin-left: -58px !important;
  }
  .tw-calculator-1 {
    width: 200px !important;
    height: 320px !important;
  }
  .sentive4 {
    width: 270px;
  }
  .sentive1 {
    width: 240px;
  }
  .btn-width-btm {
    width: 250px;
  }
  .root-aqua {
    height: 260px !important;
  }
  .root-area {
    height: 330px !important;
  }
  div.radius2.spacer-top.radius-position.col::before {
    margin-left: -70px;
  }
  .radius2.spacer-top.radius-position.col > .Mathematical-notation2.equal-position {
    margin-left: -63px !important;
  }
  .radius1 {
    display: initial;
    width: 280px;
  }
  .buyer-recipt {
    font-size: 18px;
  }
  .radius2.spacer-top {
    padding-right: 0;
    margin-top: 12px;
  }
  .radius2.spacer-top::before {
    margin-left: -36px;
  }
  .Mathematical-notation2.equal-position {
    margin-left: -29px !important;
  }
  .radius1::before {
    margin-top: 25px;
  }
  .percent-space {
    height: 40px;
    padding: 0 30px !important;
  }
  .Escrow-meney {
    padding-left: 30px;
    width: 220px;
  }
  .Mathematical-notation1 {
    margin-top: 25px;
  }
  .calculation-all {
    height: 430px;
  }
  .calculation-root {
    height: 430px !important;
  }
}

@media screen and (max-width:320px) {
  .sentive2 {
    padding-left: 8px;
  }
  .sentive4 {
    width: 230px;
  }
  .home-hero__content-text {
    padding: 0 12px!important;
  }
  .root-aqua {
    height: 300px !important;
  }
  .root-area {
    height: 340px !important;
  }
  .receipt-blue {
    width: 220px !important;
  }
  .tw-calculator-1 {
    height: 330px !important;
  }
  .buyer-recipt {
    font-size: 16px;
  }
  .calculation-all {
    height: 440px !important;
  }
  .calculation-root {
    height: 440px !important;
  }
}

@media screen and (max-width:280px) {
  h1 {
    font-size: 22px !important;
    padding: 20px 0;
  }
  .sentive2 {
    padding: 0 0 0 5px;
    font-size: 15px;
    width: 220px !important;
  }
  .sentive3 {
    font-size: 16px;
    width: 240px;
  }
  .sentive4 {
    font-size: 15px;
    width: 210px !important;
  }
  .Incentive-left {
    width: 215px;
    font-size: 15px;
  }
  .radius2.spacer-top {
    padding-right: 0;
  }
  .radius2.spacer-top::before {
    margin-left: -35px;
  }
  .Mathematical-notation2.equal-position {
    margin-left: -28px !important;
  }
  .tw-calculator-1 {
    width: 240px;
  }
  .buyer-recipt {
    font-size: 15px;
  }
  .radius1 {
    display: initial;
  }
  .root-aqua {
    padding-right: 0;
    height: 250px !important;
    margin-left: 20px;
  }
  .root-area {
    height: 320px !important;
  }
  .percent-space {
    padding-left: 20px !important;
    height: 20px !important;
  }
  .Escrow-meney  {
    padding-left: 20px;
    width: 200px;
  }
  div.radius2.spacer-top.radius-position.col::before {
    margin-left: -70px;
  }
  .radius2.spacer-top.radius-position.col > .Mathematical-notation2.equal-position {
    margin-left: -63px !important;
  }
  .seller-buyer {
    font-size: 15px;
  }
  .btn-width-btm {
    width: 250px;
  }
  div.radius2.spacer-top.radius-position.col {
    width: 200px;
  }
  .v-select__slot > label.v-label.theme--light {
    position: initial !important;
  }
  .v-input.colortext-field.testm.v-input--hide-details.v-input--dense.theme--light.v-text-field.v-text-field--single-line.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined {
    top: -20px;
  }
  .calculation-root {
    max-width: 10px;
  }
  .sentive1 {
    width: 210px;
  }
  .tw-calculator-1 {
    height: 330px !important;
  }
  .radius1::before {
  margin-top: 10px;
  }
  .Mathematical-notation1 {
    margin-top: 10px;
  }
}
</style>
