import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueI18n from 'vue-i18n'

Vue.use(Vuetify)
Vue.use(VueI18n)

const messages = {
  en: {
    $vuetify: {
      dataIterator: {
        rowsPerPageText: 'Items per page:',
        pageText: '{0}-{1} of {2}'
      }
    }
  },
  sv: {
    $vuetify: {
      dataIterator: {
        rowsPerPageText: 'Element per sida:',
        pageText: '{0}-{1} av {2}'
      }
    }
  }
}

const i18n = new VueI18n({
  locale: 'sv',
  messages
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // primary: '#4caf50',
        secondary: '#8bc34a',
        accent: '#cddc39',
        error: '#ffeb3b',
        warning: '#ffc107',
        info: '#ff5722',
        success: '#795548'
      },
      dark: {
        primary: '#ffffff',
        secondary: '#8bc34a',
        accent: '#cddc39',
        error: '#ffeb3b',
        warning: '#ffc107',
        info: '#ff5722',
        success: '#795548'
      }

    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
})
